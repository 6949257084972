%base-item-conversation {
   position: relative;
   height: 70px;
   padding: 5px;
   box-sizing: border-box;
   border-radius: 8px;
   display: flex;
   gap: 12px;
   align-items: center;
   cursor: pointer;
};

.item-conversation-active {
   @extend %base-item-conversation;
   background-color: rgb(229 231 235);
}

.item-conversation {
   @extend %base-item-conversation;

   &:hover {
      background-color: rgb(229 231 235);
   }
}





