.container-item-chat {
   .send-time {
      display: none;
   }

   &:hover {
      .send-time {
         display: block;
      }
   }
}