$color-border: #a1a1a1;
$color-border-search: #DFE1F6;

.container-messenger {
   .list-conversation {
      border-right: 1px solid $color-border;

      .search {
         .field {
            border: 1px solid $color-border-search;

            &:focus {
               outline: none;
            }
         }
      }

      .list-item-conversation {
         height: calc(100% - 110px);
      }
   }
}

.field-chat {
   border: 1px solid $color-border;
   border-radius: 10px;
   width: 100%;
   padding: 10px 10px 4px 10px;

   .textarea {
      width: 100%;
      border: none;
      &:focus {
         outline: none;
      }
   }
}