.custom-table {
  .ant-table-wrapper {
      height: 100%;
  }

  .ant-table-thead {
      position: sticky;
      top: 0;
      z-index: 1;
  }
}